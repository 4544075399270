import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardOutlined = _resolveComponent("DashboardOutlined")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_SettingOutlined = _resolveComponent("SettingOutlined")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_menu, { mode: "inline" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_menu_item, {
          key: "1",
          class: _normalizeClass([_ctx.$route.path == '/dashboard' ? 'ant-menu-item-selected' : ''])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, { to: "/dashboard" }, {
              default: _withCtx(() => [
                _createVNode(_component_DashboardOutlined),
                _cache[0] || (_cache[0] = _createElementVNode("span", null, "Dashboard", -1))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.auth_user.sidebar, (item) => {
          return (_openBlock(), _createBlock(_component_a_sub_menu, {
            key: item.id
          }, {
            title: _withCtx(() => [
              _createVNode(_component_SettingOutlined),
              _createElementVNode("span", null, _toDisplayString(item.module.name), 1)
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.role_modules, (sub) => {
                return (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: sub,
                  class: _normalizeClass([_ctx.$route.path == sub.sub_module.route ? 'ant-menu-item-selected' : ''])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: sub.sub_module.route == 'products'?'products':sub.sub_module.route
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(sub.sub_module.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1032, ["class"]))
              }), 128))
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}