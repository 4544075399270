import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__user li-pointer dropdown" }
const _hoisted_2 = { class: "dropdown-link" }
const _hoisted_3 = { class: "dropdown-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_space, null, {
      default: _withCtx(() => [
        _createElementVNode("a", _hoisted_2, [
          _createVNode(_component_UserOutlined),
          _createTextVNode(" " + _toDisplayString(_ctx.auth_user?.name), 1)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, { to: "/profile" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Profile")
        ])),
        _: 1
      }),
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
      }, "Logout")
    ])
  ]))
}