import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_Header = _resolveComponent("Header")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { style: {"min-height":"100vh"} }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_sider, {
        breakpoint: "lg",
        collapsed: _ctx.collapsed,
        "onUpdate:collapsed": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.collapsed) = $event)),
        trigger: null,
        collapsible: ""
      }, {
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "logo" }, [
            _createElementVNode("span", null, " DWSR ")
          ], -1)),
          _createVNode(_component_a_menu, {
            mode: "inline",
            selectedKeys: _ctx.selectedKeys,
            "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeys) = $event)),
            class: "sidebar"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Sidebar)
            ]),
            _: 1
          }, 8, ["selectedKeys"])
        ]),
        _: 1
      }, 8, ["collapsed"]),
      _createVNode(_component_a_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_header, null, {
            default: _withCtx(() => [
              (_ctx.collapsed)
                ? (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
                    key: 0,
                    size: "large",
                    class: "trigger",
                    onClick: _cache[2] || (_cache[2] = () => (_ctx.collapsed = !_ctx.collapsed))
                  }))
                : (_openBlock(), _createBlock(_component_menu_fold_outlined, {
                    key: 1,
                    class: "trigger",
                    onClick: _cache[3] || (_cache[3] = () => (_ctx.collapsed = !_ctx.collapsed))
                  })),
              _createVNode(_component_Header)
            ]),
            _: 1
          }),
          _createVNode(_component_a_layout_content, { style: {
          margin: '24px 16px',
          padding: '24px',
          background: '#fff',
          minHeight: '400px',
        } }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}